import * as React from "react";
import tw, { styled } from "twin.macro";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import Text from "app/styles/Text";
import { ActiveProfile } from "app/models/profile";
import { DEFAULT_PROFILE_IMG } from "app/constants/variables";
import { useProfile } from "app/providers/ProfileProvider";
import { ReactComponent as ProfileIcon } from "app/assets/icons/profile-outline.svg";
import { ReactComponent as LogoutIcon } from "app/assets/icons/logout.svg";
import { ReactComponent as InfoIcon } from "app/assets/icons/info.svg";
import { ReactComponent as CancelIcon } from "app/assets/icons/cancel.svg";
import { ReactComponent as PlayStore } from "app/assets/images/play-store.svg";
import { ReactComponent as AppStore } from "app/assets/images/app-store.svg";
// import { ReactComponent as CheckIcon } from "app/assets/icons/check.svg";
// import { ReactComponent as PlusIcon } from "app/assets/icons/plus.svg";
import { gotoSignout } from "app/lib/logout";
import {
  useActiveProfile,
  useSetActiveProfile,
} from "app/providers/ActiveProfileProvider";
import useGetProfiles from "app/hooks/useGetProfiles";

export default function ProfileDropdown() {
  const imageRef = React.useRef<HTMLImageElement>(null);

  const profile = useProfile();

  const activeProfile = useActiveProfile();

  const setActiveProfile = useSetActiveProfile();

  const { profiles } = useGetProfiles();

  const queryClient = useQueryClient();

  if (!profile || profile.status === "pending" || !activeProfile || !profiles)
    return null;

  const logout = () => gotoSignout();

  const sortedProfiles = profiles.filter((p) => p.profile_type === "personal");

  const onActiveProfileChanged = (p: ActiveProfile, cb: VoidFunction) => {
    setActiveProfile(p);
    queryClient.invalidateQueries();
    cb();
  };

  return (
    <Popover tw="relative flex">
      {({ open, close }) => (
        <>
          <Popover.Button as={Container} type="button">
            {activeProfile.profile_type === "business" && (
              <InitialSpan tw="rounded-full mr-[10px] w-[40px] h-[40px] text-[16px] flex-shrink-0">
                {activeProfile.name[0].toUpperCase()}
              </InitialSpan>
            )}
            <img
              src={profile.avatar}
              alt={activeProfile.name}
              css={[
                open && tw`mobile:hidden`,
                activeProfile.profile_type !== "personal" && tw`hidden`,
              ]}
              onError={() => {
                if (imageRef.current) {
                  imageRef.current.src = DEFAULT_PROFILE_IMG;
                }
              }}
              ref={imageRef}
            />

            <CancelIcon css={[tw`hidden`, open && tw`mobile:inline-block`]} />

            <Text mediumBold>{activeProfile.name}</Text>
          </Popover.Button>

          {/* @ts-ignore */}
          <Transition>
            {/* @ts-ignore */}
            <Transition.Child
              as={React.Fragment}
              enter="transition-opacity ease-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Overlay as={Overlay} />
            </Transition.Child>

            <Transition.Child
              as={React.Fragment}
              enter="transition duration-300 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel as={Dropdown}>
                <div tw="flex flex-col items-center pb-[16px]">
                  {activeProfile.profile_type === "business" && (
                    <InitialSpan tw="rounded-full mr-[10px] w-[64px] h-[64px] text-[24px] flex-shrink-0">
                      {activeProfile.name[0].toUpperCase()}
                    </InitialSpan>
                  )}
                  <img
                    src={profile.avatar}
                    alt={activeProfile.name}
                    css={[
                      activeProfile.profile_type !== "personal" && tw`hidden`,
                    ]}
                    onError={() => {
                      if (imageRef.current) {
                        imageRef.current.src = DEFAULT_PROFILE_IMG;
                      }
                    }}
                    ref={imageRef}
                  />

                  <Text medium tw="mt-[16px] mb-[8px]">
                    {activeProfile.name}
                  </Text>

                  {activeProfile.profile_type === "business" ? (
                    <Text medium subdued>
                      Business account
                    </Text>
                  ) : (
                    <Text medium tw="text-primary70 dark:text-primary50">
                      @{profile.username}
                    </Text>
                  )}
                </div>

                <div className="divider" tw="py-[16px]">
                  <DropdownLink as={Link} to="/profile" onClick={close}>
                    <ProfileIcon /> Profile
                  </DropdownLink>

                  <DropdownLink
                    href="https://support.busha.co/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InfoIcon /> Help center
                  </DropdownLink>
                </div>

                <div className="divider" tw="py-[12px] space-y-[8px]">
                  {sortedProfiles.map((_profile) => (
                    <div
                      key={_profile.id}
                      role="button"
                      tw="flex items-center gap-[12px] hover:bg-[#F9F9F9] dark:hover:bg-greyScale90 rounded-[8px] p-[4px]"
                      onClick={() => onActiveProfileChanged(_profile, close)}
                    >
                      <InitialSpan>
                        {_profile.name[0].toUpperCase()}
                      </InitialSpan>
                      <div tw="flex justify-between items-center flex-1">
                        <div>
                          <Text medium>{_profile.name}</Text>

                          <Text tw="text-textMid" subdued>
                            {_profile.profile_type === "personal"
                              ? "Personal account"
                              : "Business account"}
                          </Text>
                        </div>

                        {/* {_profile.id === activeProfile.id && (
                          <CheckIcon tw="dark:[&>path]:fill-[#fff]" />
                        )} */}
                      </div>
                    </div>
                  ))}

                  {/* <Link
                    to="/create-business"
                    tw="flex items-center gap-[12px] hover:bg-[#F9F9F9] dark:hover:bg-greyScale90 rounded-[8px] p-[4px]"
                  >
                    <InitialSpan>
                      <PlusIcon tw="w-[20px] h-[20px]" />
                    </InitialSpan>
                    <div tw="flex justify-between items-center flex-1">
                      <div>
                        <Text medium>Create business account</Text>
                      </div>
                    </div>
                  </Link> */}
                </div>

                {/* <div className="divider" tw="py-[16px]">
                  <div tw="pb-[8px] pl-[8px]">
                    <Text
                      semiBold
                      medium
                      tw="text-[rgba(0, 0, 0, 0.6)] dark:text-[rgba(255, 255, 255, 0.6)]"
                    >
                      Explore other Busha products
                    </Text>
                  </div>

                  <DropdownLink
                    href="https://www.busha.co/commerce"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GlobeIcon /> Accept payments for goods
                  </DropdownLink>
                </div> */}

                <div className="divider" tw="py-[16px]">
                  <div tw="pb-[8px] pl-[8px]">
                    <Text
                      semiBold
                      medium
                      tw="text-[rgba(0, 0, 0, 0.6)] dark:text-[rgba(255, 255, 255, 0.6)]"
                    >
                      Download our app
                    </Text>
                  </div>

                  <DownloadApp>
                    <a
                      aria-label="Play store"
                      title="Play store"
                      href="https://play.google.com/store/apps/details?id=co.busha.android&hl=en_US/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PlayStore />
                    </a>

                    <a
                      aria-label="App store"
                      title="App store"
                      href="https://apps.apple.com/us/app/busha-trade-btc-eth-shib/id1450373493"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AppStore />
                    </a>
                  </DownloadApp>
                </div>

                <div className="divider" tw="pt-[16px]">
                  <DropdownLink
                    as="button"
                    tw="text-error50 h-[56px] font-semibold w-full"
                    onClick={logout}
                  >
                    <LogoutIcon /> Log out
                  </DropdownLink>
                </div>
              </Popover.Panel>
            </Transition.Child>
          </Transition>
        </>
      )}
    </Popover>
  );
}

const Container = styled.button`
  ${tw`flex items-center cursor-pointer outline-none`};

  > img {
    ${tw`w-[36px] h-[36px] mr-[8px] rounded-full mobile:m-0 object-cover`};
  }

  > svg {
    ${tw`w-[24px] h-[24px]`};

    path {
      ${tw`fill-current`};
    }
  }

  p {
    ${tw`mobile:hidden`};
  }
`;

const positionStyles = tw`absolute z-10 right-[-32px] mobile:right-[-16px] top-full`;

const Overlay = styled.div`
  ${positionStyles};
  ${tw`top-[-14px] mobile:top-full mobile:mt-[14px] w-screen h-screen mobile:h-page bg-[rgba(0, 0, 0, 0.25)] dark:bg-[rgba(50, 53, 61, 0.3)]`};
`;

const Dropdown = styled.div`
  ${positionStyles};
  ${tw`mt-[22px] mobile:mt-[14px] w-[375px] mobile:w-screen max-h-[calc(100vh - 75px)] overflow-y-auto`};

  ${tw`rounded-[8px] mobile:rounded-t-none rounded-b-[8px] bg-white p-[16px] mr-[16px] mobile:mr-0`};

  ${tw`dark:bg-black`};

  img {
    ${tw`w-[64px] h-[64px] rounded-full object-cover`};
  }

  .divider {
    ${tw`border-t border-t-[#F2F2F2]`};

    ${tw`dark:border-t-white12`};
  }
`;

const DropdownLink = styled.a<{ to?: string }>`
  ${tw`flex items-center text-[1.6rem] h-[44px]`};
  ${tw`hover:bg-greyScale10 dark:hover:bg-greyScale90 rounded-[8px] px-[12px]`};

  svg {
    ${tw`w-[24px] h-[24px] mr-[8px]`};

    path {
      fill-opacity: 1;
    }
  }
`;

const DownloadApp = styled.div`
  ${tw`flex gap-[12px] px-[12px] py-[16px]`}
`;

const InitialSpan = styled.span`
  ${tw`flex justify-center items-center w-[40px] h-[40px] bg-[#E5E5E5] rounded-[8px] text-[20px] font-medium dark:text-black95`}
`;
